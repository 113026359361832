import { store } from "@/store";
import { App } from "vue";
import { MemberCountActionTypes } from "@/core/enum/MemberCount/Actions";

class MemberCount{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        MemberCount.vueInstance = app;
    }

    public static async getMemberCount() {
        return await store
        .dispatch(MemberCountActionTypes.GET_MEMBER_COUNT)
    }
}

export default MemberCount;