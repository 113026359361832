import { Health } from '@/core/types';

export type State = {
  customer: Health;
  loading: Boolean;
}

export const state: State = {
  customer: {
    // id: null,
    // lang: window.localStorage.getItem('language'),
    // company_email: null,
    // company_name: null,
    // contact_person: null,
    // contact_number: null,
    // phone_country_code: "+971",
    // no_of_members: null,
    // source: null,
    // utm_source: null,
    // utm_medium: null,
    // utm_campaign: null,
    // utm_content: null,
    uuid: null,
    id: null,
    lang: window.localStorage.getItem('language'),
    name: null,
    email: null,
    contact_person: null,
    phone_number: null,
    phone_country_code: "+971",
    insured_city: null,
    insured_type_id: 0,
    insurance_type: 1,
    is_salary: true,
    expected_premium:null,
    customer_source: null,
    is_health: 1,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    age: 0,
    dob: null,
    nationality_id: null,
    gender: null,
    is_married: false,
    is_self: null,
    agent: null,
    health_lead_map_id: 0,
    is_company: false,
    query: {},
    is_web: true,
    session_id:null,
    update_insurance_type:false,
    is_investor: false,
    no_of_members: 0,
    members:[],
    categories: [],
    is_renewal: false,
    cid:null,

  } as Health,
  loading: false,
};