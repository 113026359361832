
import { computed, defineComponent, onMounted, toRef, ref } from "vue";
import Countries from "@/core/services/Countries";
import { store } from "@/store";
import Validations from "@/core/validations";

export default defineComponent({
  props: {
    isType: {
      type: String,
      required: true,
      default: "text",
    },
    label: {
      type: String,
      required: true,
      default: "First Name",
    },
    formProp: {
      type: String,
      required: true,
      default: "first_name",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    countryCode: {
      type: String,
      required: false,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    maxLength: {
      type: String,
      required: false,
    },
    onKey: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: false,
    },
  },

  setup(props, context) {
    const isType = toRef(props, "isType");

    const isMobile = window.innerWidth < 960 ? true : false

    const country_id = ref('')
    onMounted(async () => {
      if (isType.value === "phone") await Countries.getCountryCode();

      if(isMobile) {
        const country_teleport = document.getElementsByClassName('country_teleport')[0]
        if(country_teleport)
        country_id.value = country_teleport.id
      }

      if(props.countryCode) Validations.country_code = props.countryCode
      

    });

    const countries = computed(() => {
      return store.state.countries.countryCode;
    });

    const updateValue = (event) => {
      context.emit("update:modelValue", event);
      context.emit("input", event);
    };

    const updateCountryValue = (event) => {
      search.value = ''
      Validations.country_code = event
      context.emit("update:countryCode", event);
      context.emit("change", event);
    };

    const country_ref = ref()
    const alertcountry = () => {
      // setTimeout(() => {
      //   country_ref.value.focus()
      // }, 500);
    }

    const search = ref('')
    const filtered_country = computed(() => {
      if(countries.value) {
        return countries.value.filter(obj => 
          obj.text.toString().toLowerCase().includes(search.value.toLowerCase())
        )
      }
    })

    const name = ref()
    const focus = () => {
      setTimeout(() => {
        name.value.focus()
      }, 500);
    }
    return {
      name,
      focus,
      updateValue,
      updateCountryValue,
      alertcountry,
      countries,
      isType,
      country_ref,
      country_id,
      isMobile,
      search,
      filtered_country
    };
  },
});
