
import { defineComponent, onMounted, getCurrentInstance } from "vue";
import Language from "@/core/services/Language";
import MemberCount from '@/core/services/MemberCount';
import router from "./router";
import PersonalDetails from '@/core/services/PersonalDetails';

export default defineComponent({
  created() {
    MemberCount.getMemberCount();
  },
  setup() {
    const currentInstance = getCurrentInstance();
    const dates = currentInstance?.appContext.config.globalProperties.dates;
    onMounted(async () => {
      const routeLanguage =
        router.currentRoute.value.query && router.currentRoute.value.query.lang
          ? router.currentRoute.value.query.lang.toString()
          : "";
        const language = window.localStorage.getItem("language");
      if (routeLanguage) {
        Language.setLanguage(routeLanguage);
      } else {
        if (!language) {
          Language.setLanguage("en");
        }
      }


      const routeUuid =
        router.currentRoute.value.query && router.currentRoute.value.query.uuid
          ? router.currentRoute.value.query.uuid.toString()
          : "";
      const uuid = window.sessionStorage.getItem('uuid');
      if (routeUuid) {
          window.sessionStorage.setItem('uuid', routeUuid);
      } else {
        if (!uuid) {
          window.sessionStorage.setItem('uuid', '');
        }
      }


      const pd = window.sessionStorage.getItem('pd');
      // console.log(pd);
      if (pd) {
        // console.log('pd', JSON.parse(pd));
        PersonalDetails.setCustomer(JSON.parse(pd));
      }

    });
  },
});
