export enum Links {
    /**
     * @description GET
     */
    GET_COUNTRIES = '/health/countries',
    GET_COUNTRIES_CODE = '/car/country-codes',
    GET_MEMBER_COUNT = '/health/corporate/member-count-list',
    /**
     * @description POST
     */
    POST_PERSONAL_DETAILS = '/health/corporate/profile-details',
    // GET_CUSTOMER = '/health/corporate/profile-details',
    GET_CUSTOMER = '/health/get-personal-details',
};
