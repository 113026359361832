import { store } from "@/store";

const language = window.localStorage.getItem('language') || 'en' 

class Validations {
    public static country_code = '+971'
    public static customer() {
        return store.state.personals.customer
    }
    
    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال البريد الإلكتروني للشركة' : 'Please enter company email'))
        } else if(!re.test(String(value).toLowerCase())) {
            callback(new Error( language === 'ar' ? 'يجب أن يكون عنوان البريد الإلكتروني للشركة صالحًا' : 'Company Email address must be valid'))
        } else {
            callback();
        }
    }

    public static no_of_members(rule: any, value: any, callback: any) {
        const re = /^([0-9])$/;
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء تحديد عدد الأعضاء' : 'Please select No of Members'))
        } else {
            callback();
        }
    }

    public static names(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال اسم مسؤول الاتصال' : 'Please enter contact person name'))
        } else {
            callback();
        }
    }

    public static company_names(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال اسم الشركة' : 'Please enter company name'))
        } else {
            callback();
        }
    }


    public static phone_number(rule: any, value: any, callback: any) {
        if(!value) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال رقم هاتف مسؤول الاتصال' : 'Please enter contact person phone number'))
        } 
        
        if (Validations.country_code === '+971' && (value < 9999999 || value > 9999999999)) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال 8 أرقام على الأقل و 10 أرقام كحد أقصى' : 'Please enter at least 8 digits and max 10 digits.'))
        } else if (Validations.country_code === '+961' && (value < 999999 || value > 99999999)) {
            callback(new Error( language === 'ar' ? 'الرجاء إدخال 7 أرقام على الأقل و 8 أرقام كحد أقصى' : 'Please enter at least 7 digits and max 8 digits.'))
        } else {
            callback();
        }
    }
};

export default Validations;
