import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import App from '../App.vue'
import PersonalDetails from '../views/PersonalDetails.vue';
import ThankYou from '../views/ThankYou.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/group-info',
    component: App,
    children: [
      {
        path: '/group-info',
        name: 'PersonalDetails',
        component: PersonalDetails
      },
      {
        path: '/thank-you',
        name: 'ThankYou',
        component: ThankYou
      },
    ]
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_FOLDER),
  routes
})

export default router
