
import { computed, defineComponent, onMounted, reactive, ref, getCurrentInstance } from "vue";
import { formPersonalDetails } from "@/store/stateless";
import { emirates } from "@/core/utils";
import { store } from "@/store";
import Validations from "@/core/validations";
import router from '@/router';
import personalDetails from "@/core/services/PersonalDetails";

export default defineComponent({
  setup(_) {
    const customer = computed(() => {
      return store.state.personals.customer;
    });

    const loading = computed(() => {
      return store.state.personals.loading
    })
    
    const language = computed(() => {
      return window.localStorage.getItem('language');
    })

    const selectOptions = computed(() => {

      let members =  store.state.member_count_list.member_count_list;

      members.unshift({id:0, label: 'Select', value:0});

      return members;

    })

    const inst = getCurrentInstance()


    onMounted(async() => {
      customer.value.customer_source = router.currentRoute.value.query?.source ?  router.currentRoute.value.query.source.toString() : 'website';
      customer.value.utm_source = router.currentRoute.value.query?.utm_source ?  router.currentRoute.value.query.utm_source.toString() : 'website';
      customer.value.utm_medium = router.currentRoute.value.query?.utm_medium ?  router.currentRoute.value.query.utm_medium.toString() : 'website';
      customer.value.utm_campaign = router.currentRoute.value.query?.utm_campaign ?  router.currentRoute.value.query.utm_campaign.toString() : 'nc';
      customer.value.utm_content = router.currentRoute.value.query?.utm_content ?  router.currentRoute.value.query.utm_content.toString() : 'website';
      
      const uuid = router.currentRoute.value.query?.uuid ? router.currentRoute.value.query.uuid : window.sessionStorage.getItem('uuid');
      if(uuid) {
        window.sessionStorage.clear();
        customer.value.uuid = router.currentRoute.value.query?.uuid ? router.currentRoute.value.query.uuid.toString() : window.sessionStorage.getItem('uuid');
        const result = await personalDetails.getCustomerDetails(customer.value)
      }  
      const sid = router.currentRoute.value.query && router.currentRoute.value.query.sid ?  router.currentRoute.value.query.sid.toString() : '';
      if(sid != '')
      {
        window.sessionStorage.setItem('session_id',sid);
      }

      const cid = router.currentRoute.value.query ? router.currentRoute.value.query.cid as string : null;
      if(cid) {
        console.log('renewal Customer',cid)
          
          const result = await personalDetails.getCustomerDetails({cid: cid , is_renewal: true});
          if(result) {
              console.log('renewal Customer',result)
              window.sessionStorage.clear(); // clear all session in case of renewals
              window.sessionStorage.setItem('renewal', '1')
              window.sessionStorage.setItem('cid', cid)
              // await personalDetails.setCustomerLeads(result);
          }
      }

    })
    const rules = reactive({
      name: [{ validator: Validations.company_names, trigger: ["blur","change", "input"] }],
      phone_number: [{ validator: Validations.phone_number, trigger: ["blur","change", "input"] }],
      email: [{ validator: Validations.email, trigger: ["blur","change", "input"] }],
      contact_person: [{ validator: Validations.names, trigger: ["blur","change", "input"] }],
      no_of_members: [{ validator: Validations.no_of_members, trigger: ["blur","change", "input"] }],
    });

    return {
      loading,
      language,
      formPersonalDetails,
      emirates,
      customer,
      selectOptions,
      rules,
      inst
    };
  },
});
