import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { PersonalMutationTypes } from '@/core/enum/Personals/Mutations';
import { PersonalActionTypes } from '@/core/enum/Personals/Actions';
import { Links } from '@/core/enum/Links';
import axios from 'axios';
import { Health } from '@/core/types';
import router from '@/router';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [PersonalActionTypes.POST_PERSONAL_DETAILS](
    { commit }: AugmentedActionContext,
    payload: Health
  ): any;
  [PersonalActionTypes.GET_CUSTOMER](
    { commit }: AugmentedActionContext,
    payload: Health
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [PersonalActionTypes.POST_PERSONAL_DETAILS]({ commit }, payload) {
    commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      const data = await axios.post(Links.POST_PERSONAL_DETAILS, payload);
      const personal_details = data.data.data;
      // console.log('personal_details',personal_details)
      const filtereCustomer = customer(personal_details.customer, payload);
      commit(PersonalMutationTypes.SET_CUSTOMER, filtereCustomer);

      // window.sessionStorage.setItem('personal_details', JSON.stringify(personal_details));
      window.sessionStorage.setItem('pd', JSON.stringify(filtereCustomer));
      window.sessionStorage.setItem('session_id', personal_details.session_id);
      
      if(data.data.data.uuid  != window.sessionStorage.getItem('uuid')) {
        window.sessionStorage.setItem('uuid', data.data.data.uuid)
      } 
      // console.log(data.data.data);
      commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);      
      const route = router.resolve({ name: 'ThankYou' });
      window.location.href = route.href;
    }
    catch (err) {
      console.log(err);
      return err;
    }
  },
  async [PersonalActionTypes.GET_CUSTOMER]({ commit }, payload) {
    try {
      if(payload.cid || (window.sessionStorage.getItem('uuid') != '' && window.sessionStorage.getItem('uuid') != 'undefined' && payload.name != '')) {
        const data = await axios.post(Links.GET_CUSTOMER, payload);
        let personal_details = data.data.data.customer;

        if(payload.cid)
           personal_details = data.data.data;
        
        const filtereCustomer = customer(personal_details, payload);
        commit(PersonalMutationTypes.SET_CUSTOMER, filtereCustomer);
        
        commit(PersonalMutationTypes.SET_CUSTOMER_LOADING, false);      
        window.sessionStorage.setItem('personal_details', JSON.stringify(personal_details));
        return true;
      }
    }
    catch (err) {
      return true;
      return err;
    }
  },
};


function customer(data, payload) {
  let customerD  = data
  // console.log('customerD',customerD)
  const customer = customerD.customer;
  const uuid = window.sessionStorage.getItem('uuid');
  const cid = window.sessionStorage.getItem('cid');
  const isRenewal = window.sessionStorage.getItem('renewal');
  let is_renewal = false;
  if(isRenewal)
      is_renewal = true;

  const health = {
    id: 0,
    lang: payload.lang,
    email: (customer.email != 'QL') ? customer.email : null,
    name: (customer.name != 'QL') ? customer.name : null,
    contact_person:  customerD?.contact_person ?? null,
    phone_number: (customer.phone_number != 'QL') ? customer.phone_number_without_code : null,
    phone_country_code: customer.phone_country_code ?? '+971',
    no_of_members:  customerD?.group_size ?? 0,
    customer_source: customer.customer_source ?? null,
    utm_source: customer.utm_source ?? null,
    utm_medium: customer.utm_medium ?? null,
    utm_campaign: customer.utm_campaign ?? null,
    utm_content: customer.utm_content ?? null,
    uuid: uuid,
    insured_city: null,
    insured_type_id: 0,
    insurance_type: 1,
    is_salary: true,
    expected_premium:null,
    is_health: 1,
    age: 0,
    dob: null,
    nationality_id: null,
    gender: null,
    is_married: false,
    is_self: null,
    agent: null,
    health_lead_map_id: 0,
    is_company: false,
    query: {},
    is_web: true,
    session_id:null,
    update_insurance_type:false,
    is_investor: false,
    members:[],
    
    is_renewal:is_renewal,
    cid: cid ?? null


  }
  return health;
}