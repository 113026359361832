import { App } from "vue";
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

/**
 * @description skeleton
 */
import HeaderView from '@/components/skeleton/HeaderView.vue';
import FooterView from '@/components/skeleton/FooterView.vue';
import headerDef from '@/components/skeleton/headers/DefNav.vue';

/**
 * @description reusables
 */
import ResTitle from '@/components/reusables/Title.vue';
import inputText from '@/components/reusables/InputText.vue';
import SelectDropDown from '@/components/reusables/SelectDropDown.vue';
import buttonProceed from '@/components/reusables/ButtonProceed.vue';


/**
 * @description views
 */
 import PersonalDetails from '@/components/forms/PersonalDetails.vue';

export function initComponent(app: App<Element>) {

    const data = [
        { key: 'h-header', component: HeaderView },
        { key: 'h-footer', component: FooterView },
        { key: 'h-header-default', component: headerDef },
        { key: 'h-title', component: ResTitle },
        { key: 'h-input', component: inputText },
        { key: 'h-drop-down', component: SelectDropDown },
        { key: 'h-proceed', component: buttonProceed },
        { key: 'h-personal-details', component: PersonalDetails },
    ];

    data.forEach(element => {
        app.component(element.key, element.component);
    });

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
}
