
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
      default: "Gender",
    },
    formProp: {
      type: String,
      required: true,
      default: "gender",
    },
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    placeHolder: {
      type: String,
      required: false,
      default: "",
    },
    selectOptions: {
      type: Array,
      required: false,
      default: [],
    },
    tooltipMessage: {
      type: String,
      required: false,
      default: "",
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(_, context) {


    const updateValue = (event) => {
      search.value = ''
      context.emit("update:modelValue", event);
      context.emit("change", event);
    };

    const search = ref('')

    const isMobile = window.innerWidth < 960 ? true : false

    const selected_id = ref('')
    onMounted(() => {
      if(isMobile) {
        const selected_teleport = document.getElementsByClassName('selected_teleport')[0]
        selected_id.value = selected_teleport.id
      }
    })

    const selectOptions_ref = ref()

    const alertgender = () => {
      // setTimeout(() => {
      //   gender_ref.value.focus()
      // }, 500);
    }


    return {
      updateValue,
      alertgender,
      selectOptions_ref,
      search,
      isMobile,
      selected_id
    };
  },
});
